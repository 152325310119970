* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: none;
}

p {
  color: #36003a;
}

svg {
  display: none;
}

@font-face {
  font-family: "SF UI Display Thin";
  src: url("./font/SF_UI_Display/thin.otf") format("truetype");
}
@font-face {
  font-family: "SF UI Display Light";
  src: url("./font/SF_UI_Display/light.otf") format("truetype");
}

@font-face {
  font-family: "SF UI Display Bold";
  src: url("./font/SF_UI_Display/semibold.otf") format("truetype");
}

@font-face {
  font-family: "Roxborough Thin";
  src: url("./font/Roxborough/RoxboroughCF-Thin.otf") format("truetype");
}

@font-face {
  font-family: "Roxborough Bold";
  src: url("./font/Roxborough/RoxboroughCF-DemiBold.otf") format("truetype");
}
